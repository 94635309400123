@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-size: 62.5%; */
}
html {
	font-size: 62.5%; /* 62.5% of the default font size */
	height: 100%;
}

:root {
	--primary-color: #64deba;
	--header-height: 4.8rem;
	--nav-width: 6.8rem;
	--nav-width: 6.5rem;
	--pending-yellow: rgb(255, 182, 45);
	--first-color: #454545;
	--first-color-alt: #ff6000;
	--white-color: white;
	--orange-dark: #ff5c35;
	--close-btn-red: #ff5722;
	--card-grey: #b8c2cc;
	--bg-blue1: #3498db;
	--bg-green1: #1abc9c;
	--bg-green2: #2ecc71;
	--bg-projects-purple: #8e44ad;
	--bg-support: #2c3e50;
	--bg-red: #c0392b;
	--btn-grey: #e2e2e2;
	--profile-light-blue: #b3d6fc;
	--starred-msg-orange: #f2943e;
	--delete-btn-red: #dc3545;
	--bg-light-red: #ff888b;
	--light-purple: #7578f6;
	--card-light-grey: #f9f9f9;
	background-color: #f5f5f5;
	/* Added to have div automatically take whole screen  */
	height: 91.8vh;
}

#root,
body,
main {
	height: inherit;
}
body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: large;
}
a {
	text-decoration: none;
}
.button {
	display: inline-block;
	border-radius: 4px;
	padding: 7px 12px 9px;
	line-height: 1;
	text-decoration: none;
}
ul {
	list-style: none;
}

.h2 {
	font-size: 26px;
	color: #36414c;
	line-height: 30px;
	margin-bottom: 35px;
}
.p {
	font-size: 18px;
	color: #6c7680;
	line-height: 30px;
	margin-bottom: 35px;
}

.white-btn {
	background: #fff;
	color: #7574ff;
	border: 0px;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
	font-size: 1em;
}
.white-btn:hover {
	text-decoration: none;
	background: #7574ff;
	color: #fff;
	border-bottom: 3px solid rgba(117, 116, 255, 0.2);
}

.blue-btn {
	background: #7574ff;
	color: #fff;
	border: 0px;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
	font-size: 1em;
}
.blue-btn:hover {
	text-decoration: none;
	background: #7574ff;
	color: #fff;
	border-bottom: 3px solid rgba(117, 116, 255, 0.2);
}
button:disabled {
	color: grey;
	background-color: rgb(236, 236, 236);
}
.error-message {
	color: red;
	font-size: 12px;
}
.error-icon {
	position: absolute;
	right: 1rem;
	color: #ff0000;
	/* font-size: 1.2rem; */
}
.link {
	color: inherit;
	text-decoration: none;
}
