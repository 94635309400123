.zone-map-container {
	display: grid;
	grid-template-columns: 1fr 8fr;
	align-items: center;
	position: relative;
}

.zone-map {
	display: grid;
	grid-template-columns: repeat(2, 15vw); /*Width of Cells*/
	grid-template-rows: repeat(2, 8vw); /*Height of Cells*/
	gap: 5px;
	padding: 5px;
	border-bottom: 2px solid black;
	border-left: 2px solid black;
}

.zoneContainer {
}

.zone {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2vw;
	color: white;
	font-weight: bold;
	box-shadow: inset 0 0 1px black;
}

.zone:hover {
	outline: 1px solid gray;
	transform: scale(1.05);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	cursor: pointer;
}

#zone-tooltip {
	font-size: 0.5rem;
}

.y-axis-labels {
	display: grid;
	grid-template-rows: repeat(3, 8vw);
	align-items: center;
	justify-items: end;
	margin: 0 5px;
	color: gray;
}

.x-axis-labels {
	/* grid-column: 2/3; */
	display: grid;
	grid-template-columns: 12vw 16vw;
	justify-content: space-between;
	margin: 0 5px;
	color: gray;
}

.axis-label {
	margin: 4px;
	font-size: medium;
}

.zoneAppear {
	opacity: 0;
	transform: scale(0);
	animation: zone-appear-animation 0.3s ease-in-out forwards;
}

@keyframes zone-appear-animation {
	to {
		opacity: 1;
		transform: scale(1);
	}
}

.zone.zoneInactive {
	transform: scale(1);
	z-index: 0;
	filter: blur(1.5px);
	background-color: rgba(0, 0, 0, 0.9);
}

.zone.zoneActive {
	transform: scale(1.15);
	opacity: 1;
	z-index: 1;
	background-image: radial-gradient(#abebc6, #29b866);
}

.xAxis {
	position: absolute;
	bottom: 1rem;
	right: 11rem;
	color: gray;
	font-weight: bold;
	font-size: larger;
	grid-column: 1;
	justify-self: end;
}

.yAxis {
	font-size: larger;
	position: absolute;
	left: -6rem;
	color: gray;
	transform: rotate(-90deg);
	font-weight: bold;
}

@media (max-width: 900px) {
	.zone-map {
		grid-template-columns: repeat(2, 17rem);
		grid-template-rows: repeat(2, 8rem);
	}
}
