.backdrop {
	z-index: 4;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	filter: blur(20px);
	position: fixed;
	top: 0;
	left: 0;
}
.drawerContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	height: calc(97vh - 2.4rem);
}

.drawer {
	position: fixed;
	top: 0;
	z-index: 4;
	right: -70vw;
	width: 70vw;
	height: 100%;
	background-color: #fff;
	transition: transform 0.3s ease-out;
	display: grid;
	/* grid-template-rows: 4rem 1fr 10rem; */
	grid-template-rows: 4rem 1fr 10rem;
	/* grid-template-rows:
    6.172839506172839vh 76vh
    15.432098765432098vh; */
	overflow: hidden;
}
.drawerNavbar {
	width: 100%;
	min-height: 4rem;
	border-bottom: 1px solid lightgrey;
	display: flex;
	align-items: center;
	/* background-color: green; */
}
.stepperParentContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 10rem;
	position: relative;
}
.drawer.open {
	transform: translateX(-100%); /* Slide into view */
	/* right: 0; */
}
.drawer.close {
	/* display: none; */
}
/* .homeMainContainer {
	overflow: hidden;
} */

.drawerContent {
	/* padding: 20px; */
	/* height: 51rem; */
	/* height: 77vh; */
	overflow: auto;
	z-index: 10;
	display: flex;
	flex-direction: column;
}

.openDrawerBtn {
	position: absolute;
	top: 20px;
	left: 20px;
}

.closeDrawerBtn {
	width: auto;
	height: 33px;
	color: black;
	padding: 6px;
}
.closeDrawerBtn:hover {
	transform: scale(1.08);
}
/* Skeleton Container for steps */
.stepContainer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 200px 1fr;
}

.step1LeftContainer {
	/* height: 77vh; */
	overflow: auto;
	padding-right: 1rem;
	padding-top: 1rem;
}
@media (max-width: 990px) {
	.drawerContainer {
		/* width: 100%; */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		height: calc(97vh - 2.4rem);
	}
	.drawer {
		position: fixed;
		top: 0;
		z-index: 4;
		right: -100vw;
		width: 100vw;
		height: 100%;
		background-color: #fff;
		transition: transform 0.3s ease-out;
		display: grid;
		grid-template-rows: 4rem 1fr 10rem;
		overflow: hidden;
	}
	.drawer.open {
		transform: translateX(-100%); /* Slide into view */
	}
}
