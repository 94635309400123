.error404 {
	font-size: 10rem;
	color: var(--bg-light-red);
}

.errorPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: Arial, sans-serif;
	text-align: center;
}

.errorHeading {
	font-size: 36px;
	margin-bottom: 20px;
}

.errorText {
	font-size: 18px;
}

.link {
	color: blue;
	text-decoration: underline;
}
