.settings-container {
	/* margin-top: 6rem 20rem; */
	margin: 6rem 2rem;
	padding: 2rem;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

/* Styles for the heading */
.settings-heading {
	font-size: 24px;
	margin-bottom: 1rem;
}

/* Styles for the dropdown container */
.dropdown-container {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

/* Styles for the dropdown label */
.dropdown-label {
	font-weight: bold;
	margin-right: 0.5rem;
}

/* Styles for the dropdown */
.dropdown {
	padding: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 4px;
}

/* Styles for the table */
.table {
	width: 100%;
	border-collapse: collapse;
}

/* Styles for the table header */
.table-header {
	padding: 1rem;
	text-align: center;
	border: 1px solid #ddd;
	background-color: #454545;
	color: white;
	border: 1px solid black;
}

/* Highlight the selected row */
.selected-row {
	background-color: #e0e0e0;
}

/* Styles for the button container */
.button-container {
	text-align: right;
	margin-top: 2rem;
}

/* Styles for the Save Changes button */
.save-button {
	padding: 0.5rem 1rem;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.save-button:hover {
	background-color: #0056b3;
}
/* gpt */
.table-row {
	transition: background-color 0.2s ease-in-out;
}

/* On hover, highlight the row */
.table-row:hover {
	background-color: #f5f5f5;
}

/* Styles for alternating rows */
.table-row:nth-child(even) {
	background-color: #efefef;
}
.table-cell {
	padding: 1rem;
	outline: 1px solid lightgrey;
	text-align: center;
	width: 50%;
}

/* Tab Styles */
.tabsContainer {
	display: flex;
	height: 4.2rem;
	position: sticky;
	top: 4.8rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px;
	z-index: 1;
	background-color: var(--card-light-grey);
	display: flex;
	gap: 10px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	/* padding-left: 3.5rem; */
}
.tabs {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	border: none;
	border-bottom: 2px solid transparent;
	background: none;
	font-size: 16px;
	color: #757575;
	padding: 10px 15px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.tabs:hover {
	color: grey;
	border-bottom-color: grey;
}
.active {
	border-bottom-color: black;
	color: black;
	background-color: rgb(222, 222, 222, 0.8);
}
