/* Error Class */
.errorTryAgain {
	height: 69vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	color: red;
}

.errorTryAgain p {
	font-size: 1.8rem;
}

.errorTryAgain button {
	display: flex;
	align-items: center;
	background-color: #484f56;
	border: none;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	font-size: 1.8rem;
	gap: 0.4rem;
	padding: 7px 20px;
	position: relative;
}
