.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.modalContent {
	background: white;
	padding: 20px;
	border-radius: 5px;
	text-align: center;
	display: flex;
	gap: 1rem;
	z-index: 3;
}

.loadingContainer {
	display: flex;
	gap: 1rem;
	align-items: center;
}
