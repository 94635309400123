.cardMainContainer {
	/* height: 20.7792rem; */
	height: 100%;
	width: 22rem;
	margin: 0 auto;
	display: grid;
	grid-template-rows: 3fr 1fr;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	border-radius: 1rem;
	transition: box-shadow 0.3s ease;
}
.cardTopContainer img {
	object-fit: cover;
	max-width: 100%;
	border-radius: 1rem;
}
.cardMainContainer:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cardTopContainer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 15.5rem;
	margin: 5px;
}

.cardImage {
	object-fit: cover;
	max-width: 100%;
	border-radius: 1rem;
}

.cardBottomContainer {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* justify-content: flex-start; */
	/* align-items: flex-start; */
}

.cardBottomContainer > p {
	font-size: 1.4rem;
	color: gray;
	font-weight: 500;
}

/* DROPDOWN CSS GPT */

/* DROPDOWN CSS GPT */

.cardDropdown {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dropdownIcon {
	font-size: 18px;
	color: #000;
	margin-right: 5px;
	position: absolute;
	right: 4px;
	top: 0;
	font-weight: bold;
	color: white;
}

.dropdownContent {
	position: absolute;
	top: 25px;
	right: 0;
	background-color: #fff;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1;
	padding: 10px;
	min-width: 120px;
	border-radius: 4px;
}

.dropdownContent a {
	display: block;
	color: #000;
	padding: 5px;
	text-decoration: none;
}

.dropdownContent a:hover {
	background-color: #f4f4f4;
}
