.generativeCardsContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
	height: 74vh;
	padding: 1rem;
	gap: 1rem;
	overflow: auto;
}

.cardContainer {
	border-radius: 1rem;
	height: 25rem;
	display: grid;
	/* grid-template-rows: 4fr 1fr; */
	padding: 1rem;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cardContainer:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.imgContainer {
	width: 100%;
}

.cardContainer img {
	width: 100%;
	object-fit: cover;
	/* width: 23.5rem; */
	border-radius: 1rem;
}

.cardContainer > p {
	font-weight: bold;
	display: flex;
	align-self: center;
	justify-content: center;
	color: gray;
}

.renderIcon {
	position: relative;
	top: -5.5rem;
	/* right: 0rem; */
	cursor: pointer;
}

.renderIcon:hover {
	transform: scale(1.1);
}

.tabList {
	/* background-image: linear-gradient(90deg, black, #233044); */
	background-color: #233044;
	color: white;
	display: flex;
	gap: 10px;
	padding-left: 3rem;
	padding-bottom: 1px;
	height: 4rem;
}

/* Tab button styles */
.tabList button {
	border: none;
	border-bottom: 2px solid transparent;
	background: none;
	font-size: 1.3rem;
	color: #757575;
	color: #fff;
	padding: 1rem 1.5rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.tabList div::after {
	content: "";
}

/* Active tab button styles */
.tabButton.active {
	border-bottom-color: rgb(222, 222, 222, 0.8);
	color: white;
	background-color: rgb(222, 222, 222, 0.8);
}

.tabButton.disable:hover {
	color: grey;
}

/* Tab button hover styles */
.tabButton:hover {
	color: white;
	background-color: rgba(44, 62, 80, 0.5);
	border-bottom-color: rgb(222, 222, 222, 0.8);
}
/* TABS LIST END  */

.viewerContainer {
	display: flex;
	width: 100%;
	height: 100%;
	position: relative;
}

.viewerSidebarContainer.topologyPanel {
	width: 26rem;
	position: absolute;
	top: 2px;
	z-index: 2;
	background-color: white;
	/* background-color: #ededed; */
	height: 100%;
	outline: 1px solid rgb(222, 222, 222, 0.8);
	outline: 1px solid white;
}

.viewerSidebarContainer {
	width: 30%;
	height: 100%;
	padding: 0 1rem;
	position: relative;
}

.viewerSidebarHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 1rem;
	color: #00bfff;
}

.viewerSidebarHeader span {
	padding: 0 0 5px 0;
	box-shadow: 0px 2px 0 currentColor;
}

.viewerSidebarInputTop {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
}

.viewerSidebarInputTop input,
.viewerSidebarInputTop label,
.viewerSidebarInputTop select {
	width: 100%;
	padding: 1rem;
}

.viewerSidebarInputTop label {
	color: gray;
}

.viewerSidebarInputBottom {
	padding: 1.5rem 0rem;
}

.viewerSidebarInputBottom button {
	width: 100%;
	padding: 1.2rem;
	margin-top: 1rem;
	background-color: #00bfff;
	background-color: var(--primary-color);
	background-color: var(--light-purple);
	border: none;
	border-radius: 5px;
	color: white;
	text-transform: uppercase;
	transition: all 0.2s ease-in-out;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.viewerSidebarInputBottom button:hover {
	background-color: #00bfff;
	cursor: pointer;
}

.viewerComponent {
	width: 100%;
	height: 100%;
}

/* .viewerComponent canvas,
.viewerComponent > div {
	width: 100rem !important;
	height: 100% !important;
} */
