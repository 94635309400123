.canvas-bottom {
	position: absolute;
	bottom: 10px;
	left: 10px;
	/* background-color: red; */
	color: white;
	opacity: 0.8;
	font-weight: bold;
	display: flex;
	align-items: center;
	width: 97%;
	justify-content: space-between;
}
.modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	transition: 0.2s ease-in-out;
}

.modal-content {
	background-color: #fff;
	margin: 10% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 55.5%;
	height: 67.5vh;
	/* height: 400px; */
	/* max-width: 700px; */
	/* background-color: red; */
	/* position: relative; */
	transition: 0.2s ease-in-out;
}
.canvas-main-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	outline: 1px solid black;
	flex-direction: row;
}

.canvas-icons-conainer {
	z-index: 1;
	position: absolute;
	right: 0.6rem;
	top: 0.6rem;
	display: flex;
	column-gap: 1rem;
	cursor: pointer;
}

.canvas-fullscreen-icon:hover {
	background-color: rgb(169, 169, 169, 0.8);
	transition: 100ms ease-in-out;
}
.canvas-close-icon:hover {
	background-color: red;
	transition: 100ms ease-in-out;
}

.canvas-header {
	position: absolute;
	/* background-color: red; */
	top: 0;
	right: 0;
	left: 0;
	height: 40px;
	z-index: 100;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.canvas-header div {
	padding: 10px;
	opacity: 0.9;
}
/* Renderer Sidebar tools */
.renderer-sidebar {
	width: 50px;
	background-color: white;
	outline: 1px solid lightgrey;
	display: grid;
	grid-template-columns: 1fr;
}
.tool-pointer-select {
	outline: 1px solid black;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
