/* breadcrumbs */
.breadcrumbs {
	font-size: medium;
	font-weight: 500;
	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.breadcrumbs > * {
	margin-right: 10px;
}

.breadcrumbs .crumb:hover {
	transform: scale(1.01);
	cursor: pointer;
}

.breadcrumbs .crumb {
	display: flex;
}
.breadcrumbs .crumb:after {
	content: ">";
	margin-left: 10px;
	color: gray;
}
.breadcrumbs .crumb:last-child:after {
	display: none;
}
.breadcrumbs .crumb a {
	height: 2rem;
}
.crumb a:hover {
	color: white;
	fill: white;
}
.crumb a {
	color: grey;
	fill: gray;
}
