.mapContainer {
	position: relative;
}

.mapModalClass {
	position: absolute;
	right: 0rem;
	top: 5rem;
}

.mapModalClass > button {
	background-color: white;
	border: none;
	border-radius: 0;
	box-shadow: none;
	padding: 8px;
}

.mapModalClass > button:hover {
	background-color: lightblue;
	background-color: #233043;
}

/* Added div soup due to module.css inhibiting access without styles.headerContainer variable */
.mapModalClass > div > div > div {
	top: 3rem;
	right: 6rem;
}

.mapModalClass > div > div > div > button {
	padding: 1rem;
	background-color: transparent;
	border: none;
}
