.material-tables {
	padding: 2rem;
	overflow: auto;
	outline: 1px solid lightgray;
}

.head2 {
	color: rgb(99, 99, 99);
	border-left: 5px solid #bf8c80;
	margin-bottom: 4px;
	padding: 3px 10px;
	border-bottom: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.material-table {
	border-collapse: collapse;
	width: 98%;
	margin-bottom: 20px;
	margin-left: 1rem;
	margin-top: 1rem;
}

.material-table th,
.material-table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.material-table th {
	/* background-color: #f2f2f2; */
	background-color: var(--card-grey);
}

.material-table tr:nth-child(even) {
	background-color: #f2f2f2;
}

.material-table tr:hover {
	background-color: #ddd;
}
.clickable {
	cursor: pointer;
}
