/* pop up on close drawer css */
.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.popup-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;
}

.popup-content {
	position: relative;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
	z-index: 1;
}

.popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.popup-title {
	font-size: 24px;
	font-weight: bold;
}

.popup-close-button {
	border: none;
	background: none;
	color: #ccc;
	cursor: pointer;
	font-size: 16px;
	transition: color 0.3s ease-in-out;
}

.popup-close-button:hover {
	color: #555;
}

.popup-buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.popup-button {
	margin-left: 10px;
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	outline: 1px solid lightgrey;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
}

.popup-button.primary {
	background-color: black;
	color: #ffffff;
}

.popup-button.secondary {
	background-color: #f8f9fa;
	color: #000000;
}
.popup-button.secondary:hover {
	background-color: #dcdcdc;
	color: #000000;
}

.popup-button:hover {
	opacity: 0.8;
}
