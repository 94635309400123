.spaceToggle {
	padding-left: calc(var(--nav-width) + 13.9rem);
}

.unauthorizedUser > h1 {
	font-size: 10rem;
	color: var(--bg-light-red);
}

.unauthorizedUser {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: Arial, sans-serif;
	text-align: center;
}

.unauthorizedUser > h2 {
	font-size: 36px;
	margin-bottom: 20px;
}
