.zoneMapContainer {
	margin: 5px;
	margin-bottom: 1rem;
	display: grid;
	grid-template-columns: 3fr 2fr;
	column-gap: 5px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.zoneMapContainer h2 {
	position: sticky;
	top: 0;
	text-align: center;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	padding: 1rem 0rem;
	margin-bottom: 1rem;
	color: white;
	background-image: linear-gradient(90deg, black, #233044);
}

.mapComponent {
	background-color: #eef5ff;
	padding: 4rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	grid-row: 2/3;
	grid-column: 1/2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.mapDetails {
	display: flex;
	flex-direction: column;
	background-color: #eef5ff;
	height: 42rem;
	overflow: auto;
	grid-row: 2/3;
	grid-column: 2/3;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mapDetails::-webkit-scrollbar {
	display: none;
}
.mapDetails p {
	font-size: large;
	padding: 5rem 2rem;
	color: gray;
}

.doughnutChartContainer {
	margin: 5px;
	margin-bottom: 1rem;
	border-radius: 5px;
	column-gap: 5px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.doughnutChartContainer h2 {
	width: 100%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	padding: 1rem;
	margin-bottom: 1rem;
	color: white;
	position: sticky;
	top: 0;
	text-align: center;
	background-image: linear-gradient(90deg, black, #233044);
}

.doughnutChartContainer .mapComponent {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 2rem;
}

.doughnutDetails {
	color: gray;
	display: flex;
	width: 100%;
	font-size: 2rem;
	padding-left: 1rem;
	font-weight: bold;
}

.doughnutContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 1.8rem;
	padding-left: 1rem;
}

.doughnutLegends {
	gap: 1rem;
	display: flex;
	flex-direction: column;
	color: black;
	font-weight: bold;
}

.doughnutLegendsLabel {
	display: flex;
	gap: 1rem;
}
.doughnutLegendsLabel span {
	color: gray;
}
.summaryContainer {
	outline: 1px solid black;
	height: 25vh;
	margin: 5px;
	border-radius: 5px;
}

@media (max-width: 900px) {
	.zoneMapContainer {
		grid-template-columns: auto;
		gap: 1rem;
	}

	.zoneMapContainer h2 {
		grid-row: 1/2;
	}

	.mapComponent {
		grid-row: 2/3;
		grid-column: 1/3;
	}
	.mapDetails {
		grid-row: 3/4;
		grid-column: 1/3;
		height: 30rem;
	}
}
