.partCard {
	border-radius: 5px;
	/* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.partHeader {
	text-align: center;
	margin: 3rem;
}

.partInfoContainer {
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	padding: 0.5rem;
	margin: 0.5rem;
	margin-top: 1rem;
	overflow-y: auto;
}

.partInfoContainer::-webkit-scrollbar {
	width: 8px;
}

.partInfoContainer::-webkit-scrollbar-thumb {
	background-color: #6c7278;
}

.partInfo {
	height: 78vh;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 0 1rem;
	/* height: 52vh; */
	/* width: 100%; */
}

.partSize,
.partThickness,
.partHoles {
	flex: 1;
}

.infoRow {
	/* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
	border-bottom: 1px solid lightblue;
	border-left: 4px solid black;
	color: #6c7278;
	font-weight: bold;
	width: 100%;
	padding: 1.8rem 1rem;
	border-radius: 4px;
	background-color: #f7f7f7;
}

.infoRow span {
	color: black;
	/* color: var(--bg-blue1); */
}
.infoRow div {
	display: flex;
	justify-content: space-between;
}
