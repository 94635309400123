.mainContainer {
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
}

.mainContainer h2 {
	text-align: center;
	margin: 2rem;
}

.tableContainer {
	height: 62vh;
	overflow: auto;
}

.table {
	width: 97%;
	border-collapse: collapse;
	margin: 0 auto;
	border: 1px solid #ccc;
}

.table thead tr {
	position: sticky;
	top: 0;
	background-color: var(--first-color);
	color: white;
}

.table thead tr th {
	font-weight: normal;
	background-color: var(--first-color);
	color: white;
	text-align: center;
	font-weight: bold;
	padding: 1.2rem;
	border: 1px solid #ccc;
}

.table td {
	padding: 1.2rem;
	border: 1px solid #ccc;
}

.table tr:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.03);
}
.table tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
