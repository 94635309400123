.cardsHeader {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	padding: 1rem 2rem;
	background-color: #e4e1e1;
	display: flex;
	/* height: 5rem; */
	position: sticky;
	transition: top 0.3s ease;
	top: 4.8rem;
	z-index: 2;
}
.cardsContainer {
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	padding: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 2rem;
	max-width: 100%; /* Maximum width for 4 evenly spaced columns */
	margin: 0 auto;
	justify-content: center; /* Center the columns */
}
