.analysisMainContainer {
  width: 100%;
  display: grid;
}

.backButton {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.backButton:hover svg {
  transform: scale(1.1);
  fill: black;
}

/* TABS LIST CSS */
.tabsListContainer {
  position: sticky;
  top: 4.8rem;
  z-index: 3;
  height: 4.8rem;
}

.tabListMainContainer {
  display: flex;
  flex-direction: column;
}

.tabContentContainer {
  margin-left: 3.5rem;

  margin-right: 2rem;
  flex: 1;
  padding: 2rem;
}

.tabList {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px;
  z-index: 3;
  background-color: var(--card-light-grey);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  /* margin-top: 2rem; */
  padding-left: 3.5rem;
  border-bottom: 1px solid grey;
}

/* Tab button styles */
.tabList button {
  border: none;
  border-bottom: 2px solid transparent;
  background: none;
  font-size: 16px;
  /* color: #555; */
  color: #757575;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tabList div::after {
  content: "";
  border-right: 2px solid black;
}

/* Active tab button styles */
.tabList button.active {
  border-bottom-color: black;
  color: black;
  background-color: rgb(222, 222, 222, 0.8);
}

/* Tab button hover styles */
.tabList button:hover {
  color: grey;
  border-bottom-color: grey;
}
/* TABS LIST END  */
