.step1Card {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	padding: 30px;
	display: grid;
	border-radius: 8px;
	border-left: 2px solid grey;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	position: relative;
	margin-top: 20px;
	transition: 20px;
}

.infoText {
	font-size: 12px;
	/* color: #888; */
	color: rgb(255, 182, 45);
	margin-top: 5px;
}

.deleteBtn {
	position: absolute;
	top: 10px;
	right: 10px;
}

.deleteBtn:hover {
	color: red;
}

.cardNumber {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: lightblue;
	border-radius: 50%;
	z-index: -10;
	font-size: small;
	font-weight: bold;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fade {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.green {
	color: green;
}

.partSelectDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.inputField {
	width: 100%;
	height: 36px;
	font-size: 14px;
	color: #444;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	overflow: hidden;
	padding-left: 10px;
}

.inputField:focus-visible {
	outline: none;
}

/* Hover effect */
/* .progressBar:hover::before {
	width: 100%;
	background-color: #2ecc40;
} */

/* Animation effect */
/* @keyframes progressBarAnimation {
	from {
		width: 0;
	}
}

.progressBar.animated::before {
	animation: progressBarAnimation 2s ease-in-out;
} */

/* Striped effect */
.progressBar.striped::before {
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.2),
		rgba(255, 255, 255, 0.2) 10px,
		transparent 10px,
		transparent 20px
	);
}

/* css for choose file button within the card */
.progressBar {
	background-color: #eaeaea;
	border-radius: 5px;
	height: 8px;
	width: 100%;
}

.progress {
	background-image: repeating-linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.2),
		rgba(255, 255, 255, 0.2) 10px,
		transparent 10px,
		transparent 20px
	);
	background-color: #7bbc1a;
	border: none;
	border-radius: 5px;
	height: 100%;
	outline: none;
	overflow: hidden;
	width: 2%;
}

/* DROP DOWN CSS */
.selectBtn {
	position: relative;
	display: inline-block;
	width: 120px;
	height: 3.6rem;
	font-size: 1.2rem;
	color: #444;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding-left: 10px;
	overflow: hidden;
}

.selectBtn select {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.selectBtn ~ p {
	display: flex;
	align-items: center;
	font-size: 0.2rem;
	justify-content: space-between;
	padding: 8px 12px;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.selectBtn label::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	margin-left: 8px;
	border-top: 6px solid #444;
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
}

.selectBtn:hover {
	border-color: #aaa;
}

.selectBtn:focus-within {
	/* border-color: #2196f3; */
	/* border-color: black; */
}

.selectBtn:focus-within label {
	color: #2196f3;
}

.selectBtn option {
	font-size: 14px;
	color: #444;
	background-color: #fff;
}

.selectBtn {
	width: 100%;
}

.selectBtn:focus-visible {
	outline: none;
}

/* TEST CSS  */
/* @keyframes dotAnimation {
	0%,
	100% {
		content: "Processing Files";
	}
	25% {
		content: "Processing Files.";
	}
	50% {
		content: "Processing Files..";
	}
	75% {
		content: "Processing Files...";
	}
}

.processingText {
	position: relative;
	&:before {
		content: "Processing Files...";
		animation: dotAnimation 1.5s infinite;
	}
} */

@keyframes dotAnimation {
	0%,
	100% {
		content: "Processing Files";
	}
	20% {
		content: "Processing Files.";
	}
	40% {
		content: "Processing Files..";
	}
	60% {
		content: "Processing Files...";
	}
	80% {
		content: "Processing Files....";
	}
}

.processingText {
	position: relative;
	&:before {
		content: "Processing Files...";
		animation: dotAnimation 2s infinite; /* Adjust the animation duration */
	}
}
