.createProjectBtn {
  margin-left: 20px;
  /* background-color: red; */
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: smaller;
  padding: 8px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}
.createProjectBtn > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.createProjectBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}
