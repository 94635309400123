.stepContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 200px 1fr;
}
.stepLeftContainer {
	padding-right: 1rem;
	padding-top: 1rem;
}
.stepRightContainer {
	/* background-color: blue; */
}
.stepCardContainer {
	/* width: 80%; */
	/* height: 76.8vh; */
	/* padding: 30px; */
	margin: auto;
	outline: 1px solid lightgrey;
	overflow-x: hidden;
	overflow-y: scroll;
}
.stepCardContainer::-webkit-scrollbar {
	display: none;
}
.partListHeader {
	padding: 10px;
	text-align: left;
	margin-bottom: 10px;
	height: 50px;
	color: rgb(90, 89, 89);
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stepCardContainer {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.stepRightContainer {
	flex-grow: 1;
	overflow: auto;
	/* height: 86vh; */
	outline: 1px solid lightgrey;
}

.stepCardContainer {
	background-color: #fff;
	outline: 1px solid #ddd;
	/* height: 85vh; */
	/* height: 100%; */
}

.stepTable {
	width: 99%;
	border-collapse: collapse;
	margin: auto;
}

.stepTable th,
.stepTable td {
	padding: 10px;
	text-align: left;
	height: 100%;

	/* display: flex; */
	border-bottom: 1px solid #ddd;
}

.stepTable th {
	font-weight: bold;
	/* display: flex; */
	/* align-items: center; */
}
.stepTable tr {
	height: 10px;
}
.stepTable select {
	/* width: 100px; */
	padding: 5px;
	font-size: 14px;
}

.stepTable td:first-child {
	font-weight: 500;
}
.stepTable td:nth-child(2) {
	color: grey;
}
.MdDelete:hover {
	color: var(--delete-btn-red);
}
/* .stepTable td:last-child {
	text-align: center;
} */
/* INPUT FIELD */
.inputArea {
	display: flex;
	align-items: center;
	background-color: #fff;
	justify-content: center;
	border-radius: 4px;
	border: 1px solid #ccc;
	padding: 1rem;
}

.inputArea:hover {
	outline: 1px solid black;
}
.inputArea input[type="number"] {
	flex: 1;
	border: none;
	outline: none;
	font-size: 1.2rem;
	width: 30%;
	height: 20px;
	font-size: 1.8rem;
}
.inputQty {
	width: 8rem;
	height: 3rem;
	padding-left: 0.5rem;
}

.inputArea input[type="number"]::placeholder {
	color: #b3b3b3;
}
