.stepContainer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 200px 1fr;
}

.step1LeftContainer {
	padding-right: 1rem;
	padding-top: 1rem;
}

.step1RightContainer {
	overflow: auto;
	outline: 1px solid lightgray;
	/* background-color: blue; */
}

.step1CardContainer {
	/* width: 80%; */
	/* height: 76.8vh;
   */
	/* height: 100%; */
	/* height: 51rem; */
	padding: 30px 80px;
	margin: auto;
	overflow-x: hidden;
	overflow-y: scroll;
}

.step1CardContainer::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.step1CardContainer {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.jobContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.field {
	position: relative;
	width: 100%;
}

.inputArea {
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #ccc;
	padding: 1rem;
}

.inputArea:hover {
	outline: 1px solid black;
}

.inputArea input[type="text"] {
	flex: 1;
	border: none;
	outline: none;
	font-size: 1.2rem;
	width: 100%;
}

.inputArea input[type="text"]::placeholder {
	color: #b3b3b3;
}

.selectBtn {
	position: relative;
	display: inline-block;
	width: 120px;
	height: 36px;
	font-size: 0.8rem;
	color: #444;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding-left: 10px;
	overflow: hidden;
}

.selectBtn select {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.selectBtn label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.selectBtn label::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	margin-left: 8px;
	border-top: 6px solid #444;
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
}

.selectBtn:hover {
	border-color: #aaa;
}

.selectBtn:focus-within {
	/* border-color: #2196f3; */
	/* border-color: black; */
}

.selectBtn:focus-within label {
	color: #2196f3;
}

.selectBtn option {
	font-size: 14px;
	color: #444;
	background-color: #fff;
}

.selectBtn {
	width: 100%;
}

.selectBtn:focus-visible {
	outline: none;
}

.addButton {
	padding: 19px;
	height: 25px;
	border: 1px solid lightgrey;
	margin-top: 10px;
	background-color: black;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	width: fit-content;
	cursor: pointer;
}

.addButton:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.addButton > div {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

/* CSS transition test */
.fade {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

@media (max-width: 990px) {
	.step1CardContainer {
		width: 100%;
	}
}
