.mainContainer {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.mainContainer h2 {
	text-align: center;
	margin: 1.6rem;
}

.materialAnalysisTable {
	width: 97%;
	border-collapse: collapse;
	margin: 2rem auto;
	border: 1px solid #ccc;
}

.materialAnalysisTable thead tr {
	position: sticky;
	top: 4.8rem;
	background-color: var(--first-color);
	color: white;
}

.materialAnalysisTable thead tr th {
	order: none;
	font-weight: normal;
	background-color: var(--first-color);
	color: white;
	text-align: center;
	font-weight: bold;
	padding: 12px;
	border: 1px solid #ccc;
}

.materialAnalysisTable td {
	padding: 12px;
	border: 1px solid #ccc;
}

.materialAnalysisTable tr:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.03);
}

.materialAnalysisTable tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

/* Column headers with sub headings */
.tableColumnHeader {
	display: flex;
	flex-direction: column;
}

.tableColumnHeader > span {
	font-size: 1.2rem;
	color: gray;
}
