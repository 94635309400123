main {
	position: relative;
	width: 100%;
	font-size: 1.6rem;
	font-family: "Roboto", "Helvetica Neue";
	transition: 0.5s;
	margin: calc(var(--header-height)) 0 0 0;
}
.header {
	z-index: 4;
	position: fixed;
	top: 0;
	left: 0;
	height: var(--header-height);
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-image: linear-gradient(90deg, black, #233044);
	padding: 0 1.6rem;
	transition: 0.5s;
}
.headerToggle {
	font-size: 2rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: left;
	color: var(--first-color);
}
.userProfileContainer {
	/* color: white; */
	/* outline: 1px solid white; */
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--profile-light-blue);
}
/* PROFILE DROPDOWN CSS */

/* END PROFILE DROPDOWN CSS  */
.userProfileContainer:hover {
	/* background-color: white; */
	/* color: black; */
	/* transform: scale(1.1); */
}
.sidebar {
	z-index: 4;

	position: fixed;
	top: 0;
	left: -30%;
	height: 100vh;
	width: var(--nav-width);
	background-image: linear-gradient(180deg, black, #233044);
	padding-top: 1.6rem;
	transition: 0.5s;
}
.show {
	left: 0;
}

.nav {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
}
.navLogo,
.navLink {
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	column-gap: 3.2rem;
	padding: 0.8rem 0 0.8rem 2.4rem;
}
.navLogo {
	margin-bottom: 6.4rem;
}
.navLogoIcon {
	font-size: 2rem;
	cursor: pointer;
}

.navLogoName {
	font-weight: 1200;
	color: var(--white-color);
}

.navLink {
	position: relative;
	color: var(--first-color-alt);
	transition: 0.5s;
	margin-bottom: 2.4rem;
	color: rgb(255, 255, 255, 0.7);
}

.navLink:hover {
	background-image: linear-gradient(
		45deg,
		rgb(103, 132, 177, 0.8),
		rgba(35, 48, 68, 0.8)
	);
	color: rgb(255, 255, 255, 1);
}
.navLinkName {
	font-size: 1.6rem;
}
.active {
	color: var(--white-color);
	background-color: rgba(0, 0, 0, 0.1);
}

.active::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 3.8rem;
	width: 0.3rem;
	background-color: var(--white-color);
	transition: 0.5s;
}

.show {
	left: 0;
}

.fitImage > img {
	height: 1.8rem;
	/* margin-left: -1.4rem; */
	display: block;
	margin-top: -7px;
}

.fadeInImage {
	animation: fadeIn 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.closeNav {
	width: auto;
	height: 1.6rem;
	margin-left: -1.5rem;
}

.openNav {
	width: auto;
	height: 1.6rem;
	margin-left: -0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.openNav img {
	width: auto;
	height: 1.8rem;
	margin-left: -2.4rem;
	margin-top: -3px;
}

.breadcrumbsContainer {
	display: flex;
	align-items: center;
}

.separatingLine {
	margin-left: 1rem;
	height: 3rem;
	width: 1px;
	background-color: gray;
	border: none;
}
/* MEDIA QUERIES SIDEBAR */
.spaceToggle {
	padding-left: calc(var(--nav-width) + 1.6rem);
}
@media screen and (min-width: 768px) {
	main {
		/* margin: calc(var(--header-height) + 1.6rem) 0 0 0;
    padding: 0 1.6rem 0 calc(var(--nav-width) + 3.2rem); */
		margin: calc(var(--header-height)) 0 0 0;
		padding: 0 0 0 calc(var(--nav-width));
		width: 100%;
	}

	.header {
		/* height: calc(var(--header-height) + 1rem); */
		padding-left: calc(var(--nav-width) + 3.2rem);
	}

	.sidebar {
		left: 0;
		padding: 1.6rem 0 0 0;
		/* opacity: 0.3; */
	}

	.show {
		width: calc(var(--header-height) + 15.6rem);
	}

	.spaceToggle {
		/* padding-left: calc(var(--nav-width) + 18.8rem); */
		padding-left: calc(var(--nav-width) + 17rem);
	}
	.responsiveToggle {
		display: none;
	}
}
@media screen and (max-width: 768px) {
	.spaceToggle.header {
		justify-content: end;
	}

	.spaceToggle .profileContainer,
	.spaceToggle .breadcrumbsContainer,
	.spaceToggle .separatingLine {
		display: none;
	}

	.show {
		width: 100%;
		height: 100%;
	}
	.responsiveToggle {
	}
	.profile-username p {
		display: none;
		background-color: red;
	}
	.header-profile-container {
		/* display: none; */
		outline: none;
		border: none;
	}
}
/* PROFILE DROPDOWN CSS */
.userProfileContainer {
	position: relative;
	cursor: pointer;
}

.profileDropdown {
	position: absolute;
	top: 4rem;
	right: 1.175rem;
	z-index: 1;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 1rem;
	display: none;
	animation: fadeIn 0.3s ease-in;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	animation-fill-mode: forwards;
	transform-origin: top;
}

.profileDropdown.open {
	display: block;
}

.dropdownItem {
	padding: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
	/* color: #343537; */
	color: gray;
	transition: 500ms ease;
}

.dropdownItem:hover {
	background-color: #f1f1f1;
	color: black;
	fill: black;
}
.listItem {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 1rem;
}
