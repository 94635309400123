.homeMainContainer {
	/* background-color: blue; */
	height: 91.25vh;
	width: 100%;
	overflow-y: auto;
}
/* Scrollbar styles */
.homeMainContainer::-webkit-scrollbar {
	width: 8px;
}

.homeMainContainer::-webkit-scrollbar-track {
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.homeMainContainer::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}
.topToolBarContainer {
	background-color: #e4e1e1;
	/* background-color: red; */
	width: 100%;
	height: 5rem;
	display: grid;
	grid-template-columns: 20rem 1fr 20rem;
	align-items: center;
}
.projectButtonContainer {
	grid-column: 1/2;
	display: flex;
	align-items: center;
	justify-content: center;
	/* background-color: green; */
	width: 100%;
	height: 100%;
}
.tabsListContainer {
	/* margin-left: 20px; */
}
.toolbar {
	grid-column: 3/4;
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 1rem;
	color: grey;
}
.toolbar div {
	padding: 1rem;
}
.toolbar div:hover {
	background-color: var(--card-grey);
}
/* SEARCH BAR CSS GPT */
/* home.module.css */

.searchBar {
	display: flex;
	width: 50%;
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 5px;
	justify-content: space-between;
	margin-left: 10px;
}

.searchBar input {
	border: none;
	outline: none;
	padding: 5px;
	width: 100%;
}

.searchBar input::placeholder {
	color: #999;
}

/* Tabs Data */
.tabListMainContainer {
	/* height: 80vh; */
	/* background-color: red; */
	display: flex;
	flex-direction: column;
}
.tabContentContainer {
	/* background-color: blue; */
	margin-left: 3.5rem;

	/* height: 100%; */
	/* outline: 1px solid grey; */
	margin-right: 2rem;
	flex: 1;
	padding: 2rem;
}
/* Tab list container */
.tabList {
	position: sticky;
	top: 0;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px;
	z-index: 1;
	background-color: var(--card-light-grey);
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
	margin-top: 2rem;
	padding-left: 3.5rem;
}

/* Tab button styles */
.tabList button {
	border: none;
	border-bottom: 2px solid transparent;
	background: none;
	font-size: 16px;
	/* color: #555; */
	color: #757575;
	padding: 10px 15px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.tabList div::after {
	content: "";
	border-right: 2px solid black;
}
/* Active tab button styles */
.tabList button.active {
	border-bottom-color: black;
	color: black;
	background-color: rgb(222, 222, 222, 0.8);
}

/* Tab button hover styles */
.tabList button:hover {
	color: grey;
	border-bottom-color: grey;
}
.reloadBtn {
	position: absolute;
	right: 5rem;
	display: flex;
	align-items: center;
	margin: auto auto;
	top: 50%;
	bottom: 50%;
	color: var(--card-grey);
}
.reloadBtn:hover {
	color: grey;
	transform: rotate(45deg);
}

/* Placeholder styles */
.placeholderContainer {
	color: lightgray;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 2vw;
	margin: 4rem 0;
	z-index: -1;
}

.placeholderContainer div {
	font-size: xx-large;
}
.placeholderContainer ol {
	padding: 3rem 0;
}
@media screen and (max-width: 768px) {
	.topToolBarContainer {
		background-color: #e4e1e1;
		width: 100%;
		height: 5rem;
		display: grid;
		grid-template-columns: 20rem;
		align-items: center;
	}
	.searchBar {
		display: flex;
		width: 100%;
	}
}
@media screen and (max-width: 990px) {
	.searchBar {
		display: flex;
		width: 90%;
	}
}
