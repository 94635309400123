/* switch.module.css */

/* Switch container styles */
.SwitchContainer {
	display: grid;
	align-items: center;
	gap: 1rem;
	grid-template-columns: 2fr 5fr;
	padding-left: 5px;
}

.SwitchContainer label {
	color: gray;
	font-size: 1.5rem;
	cursor: pointer;
}

.SwitchContainer label.checked {
	color: black;
}

.SwitchRoot {
	position: relative;
	width: 6rem;
	height: 3rem;
	background-color: #ccc; /* Default background color */
	border-radius: 1.7rem;
	cursor: pointer;
	width: 100%;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	transition: background-color 0.5s;
}

/* Thumb styles */
.SwitchThumb {
	position: absolute;
	top: 2px;
	left: 2px;
	/* right: 10px; */
	right: initial;
	width: 2.8rem;
	height: 2.6rem;
	background-color: #fff; /* Default thumb color */
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	transition: right 0.5s, background-color 0.5s;
}

/* Checked state styles */
.SwitchRoot.checked {
	/* Can be made as prop when making Switch custom */
	background-color: #4caf50;
	background-color: blue;
}

.SwitchRoot.checked .SwitchThumb {
	left: initial;
	right: 2px;
	background-color: #ffe;
}
