/* ListView.module.css */
.list-view {
	/* max-width: 500px; */
	margin: 0 auto;
}

.list-view h2 {
	text-align: center;
	margin-bottom: 20px;
}

.list-view ul {
	list-style: none;
	padding: 0;
}

.list-view li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 1px solid #ccc;
	margin-bottom: 10px;
	background-color: #f9f9f9;
	transition: background-color 0.2s ease;
}
.list-view li:hover {
	background-color: #e8e8e8;
}
.bookmark-icon {
	margin-right: 10px;
	font-size: 26px;
}

.bookmark-icon:hover {
	transform: scale(1.1);
}

.del-btn {
	display: flex;
	align-items: center;
}
.del-btn button {
	display: flex;
	align-items: center;
	justify-content: center;
	/* gap: 0.5em; */
}

.project-info {
	flex-grow: 1;
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	margin-right: 5rem;
}

.project-name {
	font-weight: bold;
}

.delete-button {
	background-color: var(--delete-btn-red);
	color: #fff;
	border: none;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-button:hover {
	transform: scale(1.1);
}
.delete-button span {
	display: flex;
	align-items: center;
	justify-content: center;
}
