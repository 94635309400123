.partCard {
	border-radius: 5px;
}

.partHeader {
	text-align: center;
	margin: 3rem;
}

.partInfo {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	padding-top: 2rem;
	align-items: top;
	gap: 1rem;
	overflow: auto;
	margin: 0 1rem;
	/* height: 55vh; */
	/* background-color: red; */
	height: 99%;
}

.partSize,
.partThickness,
.partHoles {
	flex: 1;
}

.infoRow {
	border-bottom: 1px solid lightblue;
	border-top: 1px solid lightblue;
	border-right: 1px solid lightblue;
	border-left: 4px solid black;
	color: #6c7278;
	border-radius: 4px;
	background-color: #f7f7f7;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
	color: gray;
	font-weight: bold;
	width: 100%;
	padding: 1.8rem 1rem;
}

.infoRow p {
	color: black;
}
.infoRow span {
	color: black;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding-top: 0.3rem;
}
.infoRow div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
