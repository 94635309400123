.mainContainer {
  display: flex;
  flex-direction: column;
  /* height: 91.8vh; */
  height: 100%;
}

.partHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
  background-image: linear-gradient(90deg, black, #233044);
  color: white;
}

.partHeader h2 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.8rem;
}

.backHeader {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  font-size: 1.6rem;
  color: gray;
  height: 5rem;
}

.backHeader span {
  padding-left: 5px;
  padding-right: 5px;
  color: gray;
}

.backHeader:hover span,
.backHeader:hover svg {
  fill: white;
  color: white;
}

.main {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
}

.rendererComponentContainer {
  height: 100%;
  padding: 6px;
  background-color: white;
  position: relative;
}

.rendererComponent {
  border-radius: 2px;
  height: 100%;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

/* TABS LIST CSS */
.tabsListContainer {
  position: sticky;
  top: 9.8rem;
  z-index: 2;
}

.tabListMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tabContentContainer {
  margin-left: 3.5rem;
  margin-right: 2rem;
  flex: 1;
  padding: 2rem;
}

.tabList {
  /* background-image: linear-gradient(90deg, black, #233044); */
  background: transparent;
  display: flex;
  gap: 10px;
  padding-left: 3rem;
}

/* Tab button styles */
.tabList button {
  border: none;
  border-bottom: 2px solid transparent;
  background: none;
  /* font-size: 1.6rem; */
  color: #757575;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tabList div::after {
  content: "";
}

/* Active tab button styles */
.tabList button.active {
  border-bottom-color: rgb(222, 222, 222, 0.8);
  color: white;
  /* background-color: rgb(222, 222, 222, 0.8); */
}

.tabList button.disable:hover {
  color: grey;
}

.tabList button.betaContainerClass {
}

.tabList button.betaContainerClass {
  /* width: 16rem; */
  position: relative;
}

.tabList button.betaContainerClass > span {
  position: absolute;
  top: 0;
  right: 2px;
  color: rgb(78, 216, 78); /* Color for Beta tag text*/
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 5px;
}

/* Tab button hover styles */
.tabList button:hover {
  color: white;
  background-color: rgba(44, 62, 80, 0.5);
  border-bottom-color: rgb(222, 222, 222, 0.8);
}

/* TABS LIST END  */
.rendererComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b8c2cc;
  position: relative;
}

.rendererComponent img {
  display: block;
  width: 100%;
}

.renderBtn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}

.renderIcon:hover {
  /* background-color: red; */
  transform: scale(1.1);
}

.menuToggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
