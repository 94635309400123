.zoneTableHeader {
	text-align: center;
	margin: 2rem 1rem;
}
.zoneTableHeader span {
	color: gray;
	margin-left: 5px;
}

.table {
	width: 97%;
	border-collapse: collapse;
	margin: 20px auto;
	border: 1px solid #ccc;
}

.table th,
.table td {
	padding: 1.2rem;
	border: 1px solid #ccc;
}

.table th {
	background-color: var(--first-color);
	color: white;
	text-align: left;
	font-weight: bold;
}

.table tbody tr:hover {
	cursor: pointer;
}

.table tbody tr:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.03);
}

.table thead tr {
	position: sticky;
	top: -1px;
	background-color: var(--first-color);
	color: white;
}

.table thead tr th {
	border: none;
	font-weight: normal;
}
/* Add hover effect for rows */
.table tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

/* Empty zone state handling */
.emptyZone {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}
@media (max-width: 1028px) {
	.table tr th:nth-child(2n) {
		display: none;
	}
	.table tr td:nth-child(2n) {
		display: none;
	}
}
@media (max-width: 900px) {
	.table tr th:nth-child(2n) {
		display: block;
	}
	.table tr td:nth-child(2n) {
		display: block;
	}
}
