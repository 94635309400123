/* Modal.module.css */

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.6s ease-in-out;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
}

.show {
	opacity: 1;
	visibility: visible;
}

.modalContent {
	background-color: #fff;
	padding: 2rem;
	border-radius: 0.8rem;
	box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.2);
	text-align: center;
	max-width: 50rem;
}

.tickContainer {
	display: inline-block;
	background-color: #66bb6a;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	position: relative;
	margin: 0 auto 15px;
}

.tickIcon {
	width: 0;
	height: 0;
	border: 3px solid #fff;
	border-top: none;
	border-right: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -75%) rotate(-45deg);
	animation: tickAnim 0.3s ease-in forwards;
}

@keyframes tickAnim {
	0% {
		width: 0;
		height: 0;
	}
	100% {
		width: 3rem;
		height: 1.5rem;
	}
}

.heading {
	/* font-size: 1.5rem; */
	font-size: 1.6rem;
	margin-bottom: 1rem;
}

.message {
	/* font-size: 1rem; */
	font-size: 1.2rem;
	margin-bottom: 20px;
}

.closeButton {
	/* background-color: #ff5722; */
	/* color: #fff; */
	background-color: black;
	outline: 1px solid black;
	font-weight: 500;
	color: white;
	border: none;
	padding: 1rem 2rem;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
	transition: background-color 0.1s ease-in-out;
}

.closeButton:hover {
	/* background-color: #e64a19; */

	box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}
