.errorMessage {
	font-size: 8rem;
	color: var(--bg-light-red);
}

.errorPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: Arial, sans-serif;
	text-align: center;
}

.errorHeading {
	font-size: 3.6rem;
	margin-bottom: 2rem;
}

.errorButton {
	font-size: 1.8rem;
	padding: 1rem;
	background-color: var(--bg-support);
}
.errorButton a {
	text-decoration: none;
	color: var(--white-color);
}
.link {
	color: blue;
	text-decoration: underline;
}
