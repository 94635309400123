.spinner {
	border: var(--border-width, 4px) solid rgba(0, 0, 0, 0.1);
	border-top: var(--border-width, 4px) solid #3498db;
	border-radius: 50%;
	width: var(--size, 24px);
	height: var(--size, 24px);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
