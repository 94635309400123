/*.adsk-viewing-viewer.dark-theme {
	background-color: #012131;
}

.adsk-viewing-viewer.dark-theme .adsk-button:hover {
	border-radius: 13px;
}

.adsk-button.active {
	border-radius: 13px !important;
}

.loading {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ViewerContainer {
	width: 100%;
	height: 100%;
}

.ForgeViewer {
	width: 100% !important;
	height: 100%;
}
*/

.skeletonLoaderContainer {
	position: relative;
}

.skeletonLoaderContainer > h1 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: relative;
	z-index: 4;
	color: gray;
}

.loading {
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: #edebed;
	color: gray;
	font-size: 3rem;
}

/* TO hide the Fullscreen toggle button in APS Viewer */
#toolbar-fullscreenTool {
	display: none;
}
