.modalBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 4;
}

.modalBody::-webkit-scrollbar-thumb {
	background-color: rgb(215, 215, 215);
	border-radius: 8px;
}

.modalBody::-webkit-scrollbar {
	width: 8px;
}

.modalBody {
	height: 70vh;
	height: 100%;
	overflow: auto;
	background-color: white;
	margin: 10% auto;
	/* padding-bottom: 1.5rem; */
	position: relative;
	top: -5rem;
	width: 90%;
	border-radius: 5px;
	display: grid;
	/* grid-template-rows: 4rem 5fr; */
}

.headerContainer {
	display: grid;
	grid-template-columns: 95% 5%;
	align-items: center;
	position: fixed;
	top: 1px;
	right: 0;
	z-index: 4;
	background-color: transparent;
	/* box-shadow: 0 0 1px 0 gray; */
}

.modalHeader {
	font-weight: 500;
	margin-left: 1rem;
	color: gray;
	background-color: transparent;
}

/* .closeButton:hover {
	background-color: gainsboro;
	color: gray;
} */

.closeButton:hover svg {
	transform: scale(1.1);
	cursor: pointer;
}

.modalChildrenContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100vh;
}

.modalChildrenContainer img {
	width: 80%;
	margin: 0 auto;
	overflow-y: hidden;
	object-fit: contain;
}

.previewButton {
	padding: 0 1rem;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 1.5rem;
	box-shadow: 0 0 1px 0 gray;
	height: 100%;
}

.previewButton:hover {
	color: white;
	background-color: rgba(44, 62, 80, 0.5);
	border-bottom-color: rgb(222, 222, 222, 0.8);
}

.fadeInPopup {
	animation: fadeIn 0.8s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.visible {
	opacity: 1;
	transform: opacity 500ms ease-in-out;
}

.hidden {
	opacity: 0;
}
